@font-face {
    font-family: DINPro-Bold;
    src: url(../fonts/DINPro-Bold_13934.ttf);
}

@keyframes boxshadow {
    from {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }
    to {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }
}

@keyframes background_size_focus {
    from {
        background-size: 0;
    }
    to {
        background-size: 100%;
    }
}

@keyframes background_size_select_focus{
    from {
        background-size: 10px 7px, 0;
    }
    to {
        background-size: 10px 7px, 100%;
    }
}

@keyframes blink{
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

html {
    height: 100%;
}

body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    min-height: 100%;
    position: relative;
    font-family: 'Oxygen',serif;
    font-size: 14px;
    min-width: 320px;
}

a,
a:link,
a:visited {
    color: #16a085;
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

ul {
    margin-top: 0;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 14px;
}

h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

.container {
    width: calc(100% - 30px);
    margin: 0 auto;
}

#header {
    font-weight: 700;
}

#header .container {
    position: relative;
}

#top-nav {
    min-height: 21px;
    padding: 10px 0;
    font-size: 15px;
    line-height: 21px;
    text-transform: uppercase;
    background-color: #004735;
}

#top-nav ul {
    margin: 0;
    padding: 0;
}

#top-nav .container {
    background-image: url(../images/logo-white-border.png);
    background-size: 25px auto;
    background-repeat: no-repeat;
    position: relative;
    z-index: 200;
}

#top-nav > .container > ul > li {
    visibility: hidden;
    margin: 0;
    display: inline-block;
}

#top-nav > .container > ul > li:before {
    content: '';
    width: 10px;
    height: 10px;
    margin: 0 10px 0 0;
    display: inline-block;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background-color: #ffab0d;
}

#top-nav > .container > ul > li + li {
    margin-left: 50px;
}

#top-nav li a,
#top-nav li a:link,
#top-nav li a:visited {
    color: #ffffff;
    text-decoration: none;
}

#user-management {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
}

#user-management > a {
    position: relative;
}

#user-management.logged-in:hover > a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -14px;
    line-height: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 14px 14px 14px;
    border-color: transparent transparent #333333 transparent;
    margin-bottom: -18px;
}

#top-nav ul#user-menu {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    border-bottom: 1px solid #282828;
    background-color: #333333;
    display: none;
    margin-top: 15px;
    text-transform: none;
    padding: 5px 0;
}

#user-management:hover #user-menu {
    display: block;
    box-sizing: border-box;
}

#user-menu li {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
}

#user-menu a, #user-menu a:link, #user-menu a:visited {
    display: block;
    padding: 10px 25px;
    box-sizing: border-box;
    cursor: pointer;
}

#user-menu li:hover {
    background-color: #666666;
}

#user-menu a:not(:last-child) {
    border-bottom: 2px solid #ffab0d;
}

#user-management a,
#user-management a:link,
#user-management a:visited {
    color: #ffffff;
    text-decoration: none;
}

#user-management:before {
    content: '';
    width: 10px;
    height: 10px;
    margin-right: 10px;
    display: inline-block;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background-color: #ffab0d;
}

#main-nav {
    min-height: 24px;
    padding: 10px 0;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    background-color: #004735;
    border-bottom: 6px solid #ffab0d;
}

#main-nav ul {
    margin: 0;
    padding: 0;
    text-align: center;
}

#main-nav li {
    margin: 0;
    padding: 0 15px;
    display: block;
}

#main-nav li + li:before {
    content: none;
    width: 10px;
    height: 10px;
    margin-right: 30px;
    display: inline-block;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background-color: #ffab0d;
}

#main-nav li a,
#main-nav li a:link,
#main-nav li a:visited {
    color: #ffffff;
    text-decoration: none;
}

#logo {
    color: #004735;
    font-family: 'DINPro-Bold',sans-serif;
    font-size: 40px;
    font-weight: 500;
    display: inline-block;
}

a#logo:link,
a#logo:visited {
    text-decoration: none;
}

#system-name {
    color: #ff0000;
    font-size: 30px;
    white-space: nowrap;
}

#content li {
    margin-bottom: 5px;
}

#footer {
    background-color: #004735;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    border-top: 15px solid #ffab0d;
    box-sizing: border-box;
    color: #ffffff;
    line-height: 25px;
}

#footer > div > div {
    text-align: center;
}

#footer > div > div:first-child {
    font-weight: bold;
}

#footer > div > div:last-child a {
    color: white;
}

#footer > div > div:last-child ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#cookies-notification {
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;
    min-width: 320px;
    padding: 15px;
    box-sizing: border-box;
    color: #ffffff;
    border-top: 4px solid #4a907f;
    background-color: #004735;
}

#cookies-notification .container div {
    text-align: center;
    padding: 10px;
}

#cookies-notification button {
    color: #424242;
    background: #e8e8e8;
    border-radius: 5px;
    font-weight: bold;
}

#cookies-notification button:hover {
    background: #ffffff;
    cursor: pointer;
}

#cookies-notification a {
    color: #72cdb9;
}

div.page-loader{
    font-size:  20px;
    text-align: center;
}

div.page-loader > span.loading-dot{
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

span.loading-dot:nth-child(2){
    animation-delay: 0.2s;
}

span.loading-dot:nth-child(3){
    animation-delay: 0.4s;
}

.page-header {
    display: table;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #b4b4b4;
}

.page-title {
    display: table-cell;
    color: #004735;
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 40px;
}

.page-actions {
    display: table-cell;
    width: 1px;
    white-space: nowrap;
    vertical-align: middle;
}

.page-actions button + button {
    margin-left: 10px;
}

.page-guide {
    margin-bottom: 30px;
    font-size: 16px;
    text-align: justify;
    margin-top: 25px;
}

.page-subtitle {
    font-size: 21px;
    margin: 20px 0 10px 0;
    border-bottom: 1px solid #e5e5e5;
}

.page-subguide{
    margin-bottom: 10px;
    text-align: justify;
    margin-top: 10px;
}

.page-subsubtitle {
    font-size: 16px;
    margin: 10px 0 5px 0;
    border-bottom: 1px solid #e5e5e5;
}

button::-moz-focus-inner {
    border: 0;
}

input {
    padding: 5px 10px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #004735;
    font: inherit;
    background: #fcfcfc;
    outline: none;
    margin: 0;
}

textarea {
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid #004735;
    resize: vertical;
    font: inherit;
    background: #fcfcfc;
    outline: none;
    min-height: 32px;
}

select {
    padding: 5px 30px 5px 10px;
    height: 32px;
    background: #fcfcfc;
    box-sizing: border-box;
    border: 1px solid #004735;
    -webkit-appearance: none;
    -moz-appearance: none;
    font: inherit;
    outline: none;
    background-size: 10px 7px;
}

select:not([multiple]){
    background-image: url(../images/tri.svg);
    background-position-x: calc(100% - 10px);
    background-position-y: center;
    background-repeat: no-repeat;
}

select[multiple]{
    height: 95px;
    padding: 5px;
}

select::-ms-expand {
    display: none;
}

input:invalid, textarea:invalid, select:invalid {
    box-shadow: none;
}

input:not([type='checkbox']):not([type='radio']):not([disabled]):not([readonly]):focus, textarea:not([disabled]):not([readonly]):focus {
    background-image: linear-gradient(to top, rgba(1, 174, 129, 0.75) 0%, rgba(1, 174, 129, 0) 4px);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    animation: background_size_focus 0.1s linear;
}

select:not([multiple]):not([disabled]):not([readonly]):focus {
    background-image: url(../images/tri.svg), linear-gradient(to top, rgba(1, 174, 129, 0.75) 0%, rgba(1, 174, 129, 0) 4px);
    background-repeat: no-repeat, no-repeat;
    background-size: 10px 7px, 100%;
    background-position: calc(100% - 10px) center, center center;
    animation: background_size_select_focus 0.1s linear;
}

input[type=file] {
    border: 0;
    background-color: white;
    padding: 0 10px;
}

input[disabled] {
    border: 1px solid #d2d2d2;
    background: #f5f5f5;
    color: #454545;
}

textarea[disabled] {
    border: 1px solid #d2d2d2;
    background: #f5f5f5;
    color: #454545;
}

select[disabled] {
    border: 1px solid #d2d2d2;
    background: #f5f5f5;
    color: #454545;
}

.date-range input, .date-range div {
    width: 45%;
    box-sizing: border-box;
}

.date-range span {
    display: inline-block;
    width: 10%;
    text-align: center;
}

.search-input-with-operator {
    display: inline-block;
}

.search-input-with-operator > select:first-child {
    width: 70px;
    box-sizing: border-box;
}

.search-input-with-operator > *:last-child {
    width: calc(100% - 75px);
    margin-left: 5px;
    box-sizing: border-box;
}

.upload input {
    width: calc(100% - 110px);
}

.upload button {
    width: 100px;
    margin: 1px 0 1px 10px;
    vertical-align: top;
}

.upload span {
    display: inline-block;
    width: calc(100% - 110px);
    line-height: 32px;
}

button {
    background-color: #004735;
    border: none;
    color: #ffffff;
    text-align: center;
    padding: 0 15px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    outline: none;
}

button:hover {
    background-color: #017457;
}

button[disabled],
fieldset:disabled button{
    color: #a5c5bc;
    background-color: #659085;
}

.popup-container {
    position: fixed;
    background: #00000080;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
}

.popup {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    max-width: 850px;
    max-height: 70vh;
    width: calc(100% - 20px);
    background: white;
    top: calc(50% - 35vh);
    position: relative;
    box-shadow: 0 0 9px #323232;
    padding: 10px;
    box-sizing: border-box;
}

.popup-title {
    color: #004735;
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 25px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #b4b4b4;
}

.popup-search {
    margin-bottom: 10px;
}

.popup-search > input {
    display: inline-block;
    width: calc(100% - 100px);
}

.popup-search > button {
    width: 90px;
    margin-left: 10px;;
}

.popup-content {
    text-align: justify;
    overflow: auto;
}

.popup-actions {
    margin-top: 10px;
}

.popup-actions button + button {
    margin-left: 10px;
}

.popup-selector label {
    width: 100px;
    display: inline-block;
}

.popup-selector input {
    width: calc(100% - 100px);
}

.popup-selector .popup-list {
    height: 300px;
    margin-top: 10px;
    border: 1px solid #004735;
    overflow-y: scroll;
}

.popup-selector .popup-list > div {
    min-height: 50px;
    padding: 10px;
    border-bottom: 1px solid #bcbcbc;
    position: relative;
    box-sizing: border-box;
}

.popup-selector .popup-list > div > button {
    position: absolute;
    right: 10px;
}

.popup-selector .popup-list > .not-found {
    border: 0;
    height: 300px;
    line-height: 300px;
    text-align: center;
    font-size: 20px;
    font-style: italic;
    opacity: 0.5;
    padding: 0;
}

.popup-selector .popup-header > form > div {
    position: relative;
}

.popup-selector .popup-header .search-with-button {
    width: calc(100% - 100px);
    display: inline-block;
}

.popup-selector .popup-header .search-with-button + div {
    display: inline-block;
    width: 100px;
    text-align: center;
}

.popup-selector .popup-header > form > div > button {
    line-height: 32px;
    height: 32px;
}

.popup-selector .popup-name {
    overflow-x: hidden;
    width:calc(100% - 90px);
}

.popup-selector .popup-description {
    color: grey;
    font-size: 12px;
    overflow-x: hidden;
    width:calc(100% - 90px);
}

.actions button {
    display: block;
    width: 75%;
    margin: 10px auto;
}

.actions button + button {
    margin: 10px auto;
}

.welcome {
    font-size: 20px;
    font-weight: bold;
    margin: 40px 0 30px 0;
}

.welcome-description {
    font-size: 16px;
    text-align: justify;
}

.block,
.block-without-margin{
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 2px;
    vertical-align: top;
}

.block{
    margin-bottom: 10px;
}

.block-1-2, .block-2-4, .block-3-6{
    width: 50%;
}

.block-1-3, .block-2-6{
    width: 33.33%;
}

.block-2-3, .block-4-6{
    width: 66.66%;
}

.block-1-4 {
    width: 25%;
}

.block-3-4 {
    width: 75%;
}

.block-1-6{
    width: 16.66%;
}
.block-5-6{
    width: 83.33%;
}

.block-1-5{
    width: 20%;
}

.block-2-5{
    width: 40%;
}

.block-3-5{
    width: 60%;
}

.block-4-5{
    width: 80%;
}

.label {
    font-weight: bold;
    display: block;
    padding: 0 5px;
    box-sizing: border-box;
    line-height: 32px;
}

.block > .label + *,
.block-without-margin > .label + *{
    width: 100%;
}

.block > .label + .text-container,
.block-without-margin > .label + .text-container {
    line-height: 32px;
}

label.required:after {
    content: '*';
    color: #ff0000;
}

.field {
    min-height: 32px;
}

div.field {
    padding: 7px 10px 5px 10px;
    border: 1px solid #d2d2d2;
    background: #f5f5f5;
    display: inline-block;
    box-sizing: border-box;
    color: #454545;
    overflow: hidden;
    margin: 0;
    vertical-align: middle;
    white-space: pre;
}

a.field{
    padding:7px 10px 5px 10px;
    display:inline-block;
    box-sizing:border-box;
    overflow:hidden;
    margin:0;
    vertical-align:middle;
    white-space:pre;
}

.field-text {
    height: 64px;
}

div.field-text {
    resize: vertical;
    overflow: auto;
    white-space: pre-line;
}

.input-ujx div {
    display: inline-block;
    box-sizing: border-box;
    width: calc(100% - 32px);
    padding: 5px 10px;
    margin: 0;
    height: 32px;
    border: 1px solid #004735;
    background: #fcfcfc;
    outline: none;
    overflow-x:hidden;
    line-height:22px;
}

.input-ujx input {
    display: inline-block;
    box-sizing: border-box;
    width: calc(100% - 32px);
    padding: 5px 10px;
    margin: 0;
    height: 32px;
    border: 1px solid #004735;
    background: #fcfcfc;
    outline: none;
    overflow-x:hidden;
    line-height:22px;
}

.input-ujx button {
    width: 32px;
    padding: 0;
    vertical-align: top;
    border: 1px solid #004735;
    height: 32px;
    line-height: 32px;
    border-left: none;
    background-color: #fcfcfc;
    color: #004735;
}

.page-tabs {
    font-size: 21px;
    margin: 20px 0;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
}

.page-tabs ul {
    margin: 0;
    padding: 0;
}

.page-tabs li {
    margin: 0 20px;
    display: inline-block;
    color: grey;
    cursor: pointer;
}

.page-tabs li.active {
    color: black;
    border-bottom: 3px solid #ffab0d;
}

.page-tabs > button{
    vertical-align: top;
}

.scroll-page-tabs{
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    scrollbar-color: #004735 #b4dcd2;
    scrollbar-width: thin;
}
.scroll-page-tabs > li{
    margin-bottom:  5px;
}
ul.scroll-page-tabs::-webkit-scrollbar-thumb {
    background-color: #004735;
    border-radius: 5px;
}

ul.scroll-page-tabs::-webkit-scrollbar {
    background-color: #b4dcd2;
    height: 5px;
    border-radius: 5px;
}

.page-tabs button + ul.scroll-page-tabs{
    width: calc(100% - 144px);
}

.page-tabs button {
    margin: 0 20px;
    display: inline-block;
    color: grey;
    background-color: transparent;
    cursor: pointer;
    width: 32px;
    box-sizing: border-box;
}

div.site-tab{
    display: inline-block;
    width: 50px;
}
div.site-tab + div{
    display: inline-block;
    width: calc(100% - 50px);
    vertical-align: top;
}

div.site-tab > div {
    display: table;
}

div.site-tab:not(.required){
    display: none;
}
div.site-tab:not(.required) + div{
    width: 100%;
}

ul.site-tab-list > li{
    display: block;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
    height: 50px;
    margin-top: calc(100% - 50px);
    margin-bottom: 0;
    white-space: nowrap;
    cursor: pointer;
    color: grey;
}

ul.site-tab-list > li:not(.active):empty {
    width: 20px;
    height:20px;
    margin-top: 0;
    pointer-events: none;
    padding: 0;
    border-bottom-style: dashed;
}

ul.site-tab-list>li:not(.active){
    border-bottom: 1px solid #4a907f;
}

ul.site-tab-list>li.active{
    border-left: 1px solid #4a907f;
    border-top: 1px solid #4a907f;
    border-right: 1px solid #4a907f;
}

div#dashboard div.menu {
    margin: 0 auto;
}

div#dashboard div.menu div.dashboard-logo {
    height: 150px;
    width: 150px;
    background-size: 100% 100%;
    border-radius: 50%;
    border: 4px solid #ffab0d;
    transition: border-color 0.5s;
    margin: 0 auto;
}

div#dashboard  div.menu:hover div.dashboard-logo {
    border-color: #004735;
}

div#dashboard  div.menu div.dashboard-title {
    margin: 20px auto;
    text-transform: uppercase;
    color: #004735;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

div#dashboard  div.menu a,
div#dashboard  div.menu a:link,
div#dashboard  div.menu a:visited
div#dashboard  div.menu a:hover {
    text-decoration: none;
}

table {
    width: 100%;
    border: 0;
    border-spacing: 0;
}

tbody, caption {
    color: #004735;
}

th {
    border-style: solid;
    border-color: #cccccc;
    border-width: 1px 1px 0 0;
    background-color: #4a907f;
    color: #ffffff;
    vertical-align: middle;
    padding: 5px;
}

th:first-child {
    border-width: 1px 1px 0 1px;
}

th.required:after {
    content: '*';
}

td {
    border-bottom: 1px solid #cccccc;
    padding: 5px;
    height: 32px;
    box-sizing: border-box;
}

tr:first-child > td {
    border-top: 1px solid #cccccc;
}

tr.hover-show-row:not(:hover) td.show-only-hover{
    font-size: 0;
}

table.striped > tbody > tr:nth-child(2n) > td {
    background-color: #f0f8f0;
}

table.row-highlight > tbody > tr:hover > td {
    background-color: #a9c7c0;
    color:#FFFFFF;
}

table > tbody > tr.selected-row:nth-child(n)> td{
    background-color: #3e6e62;
    color: white;
}

tfoot > tr > td {
    border-width: 2px 0 1px 0;
    font-weight: bold;
}

div.pager {
    text-align: right;
}

ul.pager{
    display: inline-block;
    text-align: right;
    padding-left: 0;
}

ul.pager > li {
    display: inline-block;
    padding: 7px 10px;
    background-color:#004735;
    margin-left: 5px;
    color: #ffffff;
    border: 1px solid #cccccc;
}

ul.pager > li[disabled] {
    pointer-events: none;
    opacity: 0.5;
}

ul.pager >li:not(.active):not([disabled]) {
    cursor: pointer;
}

ul.pager > li.active {
    color: #000000;
    background-color: #ffab0d;
    font-weight: bold;
}

.table-thin-container,
.table-shadow-container,
.table-container {
    width: 100%;
    overflow-x: auto;
}

.table-shadow-container,
.table-container {
    margin-bottom: 20px;
}

.table-shadow-container{
    animation: boxshadow 0.5s linear;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

table.striped > tbody > tr > td.col-actions {
    background-color: inherit;
}

.table-new-row td:first-child {
    text-align: center;
    font-style: italic;
}

.table-empty-indicator td:first-child {
    text-align: center;
    font-style: italic;
}

table.modify-table input,
table.modify-table select {
    width: 100%;
}

table.modify-table thead th {
    padding: 5px 0;
}

table.modify-table > thead >tr > th.col-actions,
table.modify-table > tbody >tr:not(.selected-row) > td.col-actions,
table.modify-table > tbody >tr.selected-row > td.col-actions{
    border: none;
    width: 32px;
    background-color: transparent;
}

table.modify-table > tbody > tr.table-new-row > td {
    border: none;
    color: inherit;
    background-color: inherit;
}

table.modify-table > tbody >  tr.removed > td:not(.col-actions) {
    opacity: 0.5;
    font-style: italic;
}

a.download,span.information{
    height: 24px;
}

.modify-table button,
.table-new-row button,
button.remove,
button.undo,
button.add,
button.left,
button.right,
button.copy,
button.edit,
button.download,
button.settings,
a.download,
span.information{
    box-sizing: border-box;
    display: inline-block;
    width: 24px;
    border: none;
    outline: none;
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
}

span.information{
    width: 18px;
    background-size: 14px 14px;
}

button.remove{
    background-image: url(../images/minus.svg);
}

button.undo{
    background-image: url(../images/undo.svg);
}

.table-new-row button,
button.add{
    background-image: url(../images/plus.svg);
}

button.left{
    background-image: url(../images/left.svg);
}

button.right{
    background-image: url(../images/right.svg);
}
button.copy{
    background-image: url(../images/copy.svg);
}
button.copy{
    background-image: url(../images/copy.svg);
}
button.edit{
    background-image: url(../images/edit.svg);
}
button.download,
a.download{
    background-image: url(../images/download.svg);
}
button.settings{
    background-image: url(../images/settings.svg);
}

span.information{
    background-image: url(../images/information.svg);
}

th > span.information{
    filter: grayscale(100%) brightness(50000%);
    height: 17px;
    width: 17px;
    background-size: 17px 17px;
    vertical-align: top;
    margin-left: 5px;
}

.modify-table button:disabled,
.table-new-row button:disabled{
    opacity: 0.6;
}

table.list {
    width: initial;
}

table.list tr td {
    border-top: none;
    border-bottom: none;
    padding-right: 15px;
}

table.order > thead > tr > th {
            user-select: none;
        -ms-user-select: none;
       -moz-user-select: none;
    -webkit-user-select: none;
    padding-left: 22px;
    padding-right: 22px;
}

table.order > thead > tr > th.asc, table.order > thead > tr > th.desc {
    position: relative;
}

table.order > thead > tr > th.asc:after, table.order > thead > tr > th.desc:after {
    position: absolute;
    right: 0;
    top: calc(50% - 9px);
    height: 18px;
    width: 18px;
    color: #FFFFFF;
    line-height: 18px;
    vertical-align: middle;
    margin-right: 2px;
    font-size: 18px;
}

table.order > thead > tr > th.asc:after {
    content: "▴";
}

table.order > thead > tr > th.desc:after {
    content: "▾";
}

table.search-result > tbody > tr > td {
    height: 50px;
}

table.search-result > tbody > tr > td > div {
    max-height: 34px;
    overflow-y: hidden;
}

.result-summary {
    padding: 8px 11px;
}

.messages {
    padding: 10px;
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;
    white-space: pre-wrap;
    color: #000000;
    border: 1px solid #626262;
}

.messages ul {
    padding-left: 20px;
    margin-bottom: 0;
}

.messages.error {
    color: #b7000f;
    border-color: #ed541d;
    background: url(../images/error.svg) 10px 7px no-repeat;
    background-color: #fef5f1;
    padding-left: 50px;
}

.messages.warning {
    color: #b36b28;
    border-color: #e0924a;
    background: url(../images/warning.svg) 10px 7px no-repeat;
    background-color: #fffce5;
    padding-left: 50px;
}

.messages.success {
    color: #004735;
    border-color: #24925c;
    background: url(../images/success.svg) 10px 7px no-repeat;
    background-color: #f8fff0;
    padding-left: 50px;
}

input[type="checkbox"]{
    width: 22px;
    height: 22px;
    background-color: transparent;
    color: transparent;
    border-width: 0;
    margin: 0;
    padding: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-block;
}

.block > .label-short + input[type="checkbox"],
.block > .label-long + input[type="checkbox"],
.block > .label-super-long + input[type="checkbox"]{
    width: 22px;
    margin-top: 6px;
}

input[type="checkbox"]:checked[disabled]{
    opacity: 0.5;
}

input[type="checkbox"][disabled]::after{
    border: 1px solid #d2d2d2;
    background-color: #f5f5f5;
}

input[type="checkbox"]::after{
    background-color: #fff;
    color: transparent;
    border-color: #004735;
    border-width: 1px;
    border-style: solid;
    content: '\2713';
    height: 20px;
    width: 20px;
    position: relative;
    padding: 0;
    display: block;
    position: relative;
    text-align: center;
    font-size: 14px;
}
input[type="checkbox"]:checked::after {
    background-color: #004735;
    color: #FFFFFF;
}

input[type="checkbox"], input[type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type="checkbox"], input[type="radio"] {
    line-height: normal;
}

.checkbox-label{
    padding: 0 5px;
    box-sizing: border-box;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    max-width: calc(100% - 22px);
}

.password-strongness{
    padding: 7px 10px 5px 10px;
    display: inline-block;
    box-sizing: border-box;
    border-color: #004735;
    height: 16px;
    vertical-align: middle;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 10px;
    margin-left: 0px;
}

.password-info{
    padding: 7px 10px 5px 10px;
    display: inline-block;
    overflow: hidden;
    margin: 0;
    vertical-align: middle;
    white-space: pre;
    width: 100%;
    box-sizing: border-box;
    background-position-x: left;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 24px;
    padding-left: 42px;
}

.password-info.equivalent,
.password-info.good{
    color: #017457;
    background: url(../images/success.svg) 10px center no-repeat;
}

.password-info.acceptable{
    color: #835500;
    background: url(../images/warning.svg) 10px center no-repeat;
}

.password-info.non-equivalent,
.password-info.weak{
    color: #8c2e0b;
    background: url(../images/error.svg) 10px center no-repeat;
}

@media(min-width: 576px) {
    #cookies-notification .container div {
        display: table-cell;
        text-align: left;
        vertical-align: middle;
    }

    #cookies-notification .container div:first-child {
        padding-left: 0;
    }

    #cookies-notification .container div:last-child {
        padding-right: 0;
    }

    .block-sm-1-1{
        width: 100%;
    }
     
    .block-sm-1-2, .block-sm-2-4, .block-sm-3-6{
        width: 50%;
    }

    .block-sm-1-3, .block-sm-2-6{
        width: 33.33%;
    }

    .block-sm-2-3, .block-sm-4-6{
        width: 66.66%;
    }

    .block-sm-1-4 {
        width: 25%;
    }

    .block-sm-3-4 {
        width: 75%;
    }

    .block-sm-1-6{
        width: 16.66%;
    }
    .block-sm-5-6{
        width: 83.33%;
    }
    .block-sm-1-5{
        width: 20%;
    }
    
    .block-sm-2-5{
        width: 40%;
    }
    
    .block-sm-3-5{
        width: 60%;
    }
    
    .block-sm-4-5{
        width: 80%;
    }

    .label {
        display: inline-block;
        vertical-align: top;
    }

    .label-short {
        width: 100px;
    }

    .label-long {
        width: 200px;
    }

    .label-super-long {
        width: 300px;
    }

    .block > .label + * {
        box-sizing: border-box;
        display: inline-block;
    }

    .block > .label-short + * {
        width: calc(100% - 100px);
    }

    .block > .label-long + * {
        width: calc(100% - 200px);
    }

    .block > .label-super-long + * {
        width: calc(100% - 300px);
    }

    .actions {
        margin-top: 15px;
    }

    .actions button {
        min-width: 120px;
        width: auto;
        display: inline-block;
        margin: 0;
    }

    .actions button + button {
        margin: 0 0 0 10px;
    }
}

@media(min-width: 768px) {
    body::after {
        content: '';
        display: block;
        height: 120px;
    }

    #footer {
        margin-top: -100px;
        height: 100px;
        bottom: 0;
        position: absolute;
    }

    #footer > div > div {
        width: 50%;
        display: inline-block;
        box-sizing: border-box;
    }

    #footer > div > div:first-child {
        text-align: right;
        border-right: 1px solid #b4b4b4;
        padding-right: 20px;
    }

    #footer > div > div:last-child {
        text-align: left;
        padding-left: 20px;
    }

    #footer > div > div:first-child:last-child {
        width: 100%;
        text-align: center;
        border-right: none;
    }

    #footer > div > div:last-child ul > li {
        display: inline-block;
    }

    #footer > div > div:last-child ul > li + li:before {
        content: '\2022';
        padding: 0 10px;
    }

    .block-md-1-1{
        width: 100%;
    }

    .block-md-1-2, .block-md-2-4, .block-md-3-6{
        width: 50%;
    }

    .block-md-1-3, .block-md-2-6{
        width: 33.33%;
    }

    .block-md-2-3, .block-md-4-6{
        width: 66.66%;
    }

    .block-md-1-4 {
        width: 25%;
    }

    .block-md-3-4 {
        width: 75%;
    }

    .block-md-1-6{
        width: 16.66%;
    }

    .block-md-5-6{
        width: 83.33%;
    }

    .block-md-1-5{
        width: 20%;
    }
    
    .block-md-2-5{
        width: 40%;
    }
    
    .block-md-3-5{
        width: 60%;
    }
    
    .block-md-4-5{
        width: 80%;
    }
}

@media(min-width: 992px) {
    #logo {
        height: 160px;
        line-height: 160px;
        padding-left: 130px;
        background: url(../images/logo.png) 0 50% no-repeat;
    }

    #system-name {
        position: absolute;
        right: 0;
        padding: 10px;
        margin-top: 50px;
        margin-right: 30px;
        border: 3px dashed #ff0000;
        border-radius: 5px;
        transform: rotate(5deg);
        text-transform: uppercase;
    }

    #main-nav li {
        display: inline-block;
    }

    #main-nav li + li::before {
        content: '';
    }

    #top-nav .container {
        background: none;
    }

    #top-nav > .container > ul > li {
        visibility: visible;
    }

    .container {
        width: 970px;
    }

    .block-lg-1-1{
        width: 100%;
    }

    .block-lg-1-2, .block-lg-2-4, .block-lg-3-6{
        width: 50%;
    }

    .block-lg-1-3, .block-lg-2-6{
        width: 33.33%;
    }

    .block-lg-2-3, .block-lg-4-6{
        width: 66.66%;
    }

    .block-lg-1-4 {
        width: 25%;
    }

    .block-lg-3-4 {
        width: 75%;
    }

    .block-lg-1-6{
        width: 16.66%;
    }

    .block-lg-5-6{
        width: 83.33%;
    }

    .block-lg-1-5{
        width: 20%;
    }
    
    .block-lg-2-5{
        width: 40%;
    }
    
    .block-lg-3-5{
        width: 60%;
    }
    
    .block-lg-4-5{
        width: 80%;
    }

    div.page-tabs.needscroll{
        visibility: hidden;
        height: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }

    div.site-tab:not(.required){
        display: inline-block;
    }
    div.site-tab:not(.required) + div{
        width: calc(100% - 50px);
    }
}

@media(min-width: 1200px) {
    .container {
        width: 1140px;
    }

    .block-xl-1-1{
        width: 100%;
    }

    .block-xl-1-2, .block-xl-2-4, .block-xl-3-6{
        width: 50%;
    }

    .block-xl-1-3, .block-xl-2-6{
        width: 33.33%;
    }

    .block-xl-2-3, .block-xl-4-6{
        width: 66.66%;
    }

    .block-xl-1-4 {
        width: 25%;
    }

    .block-xl-3-4 {
        width: 75%;
    }

    .block-xl-1-6{
        width: 16.66%;
    }
    
    .block-xl-5-6{
        width: 83.33%;
    }

    .block-xl-1-5{
        width: 20%;
    }
    
    .block-xl-2-5{
        width: 40%;
    }
    
    .block-xl-3-5{
        width: 60%;
    }
    
    .block-xl-4-5{
        width: 80%;
    }
}
